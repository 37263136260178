import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const { t } = useTranslation("header");
  return (
    <div style={{ width: "100%", position: "relative", zIndex: 100000 }}>
      <img src={t("img")} style={{ width: "100%", zIndex: 1000 }} />
    </div>
  );
};

export default Header;
