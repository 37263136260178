import acro0 from "../../../assets/content/acro-0.en.png"

export default {
    title: "An experimental regenerative-agriculture orchard at Andros",
    subtitle: "Agroecology represents the future of agriculture to continue producing food to feed people while preserving ecosystems.",
    block1: "In short, thanks to the technique of plant cover at the base of fruit tree rows, there is more life in the soil, which means more fertility. Therefore, less input is required, less water is used thanks to the moisture maintained, and more carbon is absorbed!",
    block2: "This strong conviction for the interest of agroecology incited Andros to plant a 15-hectare orchard in Biars-sur-Cère just in front of the company’s headquarters where all the fruit species capable of growing in this part of the world are present.",
    block3: "The objective? To move on to the practical work of agroecology to convince our tree-grower suppliers of the effectiveness of these new agronomic techniques associated with mechanical innovation and of their economic viability.",
    block4: "A unique place that demonstrates Andros’s fruit-growing know-how and illustrates its desire to accompany its upstream tree-growers towards agriculture that is more concerned with the health of our planet.",
    acro0
}