export default {
    title: "A sharp drop in Andros’s CO2 emissions",
    subtitle: {
        normal: "Andros has set itself the ambitious goal of",
        bold: "achieving carbon neutrality in its production facilities by 2025."
    },
    steps: {
        title: "3 steps are necessary to reach this goal:",
        measure: "measure our CO2 emissions,",
        reduce: "reduce our emissions as much as possible, ",
        compensate: "offset our residual emissions by purchasing carbon credits to finance carbon reduction or storage projects."
    },
    block1: "Andros conducted the 1st annual carbon footprint assessment of its activities in 2011. This assessment is now annual.",
    block2: {
        normal: "Between 2011 and 2021, we reduced our emissions by 34%.",
        bold: "In 2022, we will make further significant progress, as we expect to achieve a 45% reduction by the end of the year compared to 2011.",
    },
    block3: "We will continue our reduction efforts until 2025, when we offset our emissions by purchasing carbon credits."
}