import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ecowrapDispenser from "../../assets/content/ecowrap-dispenser.png";

const EcoWrap: React.FC = () => {
  const { t } = useTranslation("ecowrap");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <img
        src={ecowrapDispenser}
        style={{ width: "100%" }}
        className="card-image"
      />
      <Typography variant="body1" gutterBottom>
        {t("block1")}
      </Typography>
      <p>{t("block2")}</p>
      <p>
        {t("block3.normal1")}{" "}
        <span style={{ fontWeight: "bold" }}> {t("block3.bold")}</span>
        {t("block3.normal2")}
      </p>

      <img
        src={t("ecowrapList")}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
    </div>
  );
};

export default EcoWrap;
