import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import solar from "../../assets/content/solarpanels.jpg";

const SolarPanels: React.FC = () => {
  const { t } = useTranslation("solarpanels");
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <img
        src={solar}
        style={{
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
        className="card-image"
      />
      <Typography variant="body1" gutterBottom>
        {t("subtitle.normal")}
        <span style={{ fontWeight: "bold" }}>{t("subtitle.bold")}</span>
      </Typography>
      <Typography marginTop={"1em"}>{t("steps.title")}</Typography>
      <ul>
        <li>{t("steps.measure")}</li>
        <li style={{ fontWeight: "bold" }}>{t("steps.reduce")}</li>
        <li>
          <span>{t("steps.compensate")}</span>
        </li>
      </ul>
      <p>{t("block1")}</p>
      <p style={{ fontWeight: "bold" }}>{t("block2")}</p>
      <ul>
        <li>{t("list1")},</li>
        <li>{t("list2")}</li>
        <li>{t("list3")}</li>
        <li>{t("list4")}</li>
        <li style={{ fontWeight: "bold" }}>{t("list5")}</li>
      </ul>
      <p>{t("block3")}</p>
    </div>
  );
};

export default SolarPanels;
