import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import acro1 from "../../assets/content/acro-1.jpg";
import acro2 from "../../assets/content/acro-2.jpg";

const Orchard: React.FC = () => {
  const { t } = useTranslation("orchard");

  const params = useParams();
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("subtitle")}
      </Typography>
      <img src={t("acro0")} style={{ width: "100%" }} className="card-image" />
      <p style={{ fontWeight: "bold" }}>{t("block1")}</p>
      <img src={acro1} style={{ width: "100%" }} className="card-image" />
      <p>{t("block2")}</p>
      <p>{t("block3")}</p>
      <img src={acro2} style={{ width: "100%" }} className="card-image" />
      <p>{t("block4")}</p>
      {/* {params.id === "andros-land" ? (
        <img src={acro3gen} style={{ width: "100%" }} className="card-image" />
      ) : (
        <img src={acro3} style={{ width: "100%" }} className="card-image" />
      )} */}
    </div>
  );
};

export default Orchard;
