import autistics from "./content/fr/autistics";
import carboncompensating from "./content/fr/carboncompensating";
import carbonneutralcandy from "./content/fr/carbonneutralcandy";
import carbonreducing from "./content/fr/carbonreducing";
import committed from "./content/fr/committed";
import ecowrap from "./content/fr/ecowrap";
import orchard from "./content/fr/orchard";
import orchardwatering from "./content/fr/orchardwatering";
import solarpanels from "./content/fr/solarpanels";
import sustainable from "./content/fr/sustainable";
import vehiclefleet from "./content/fr/vehiclefleet";
import waterplant from "./content/fr/waterplant";
import img from "../assets/header.png"

export default {
    onboarding: {
        loading: {
            text: "Nous préparons votre expérience personnalisée, veuillez patienter"
        },
        error: {
            text: "Nous n'arrivons pas à trouver et préparer votre environnement personnalisé, veuillez contacter Andros",
        },
        portraitLandscape: {
            text: 'Passez en mode paysage'
        },
        tooSmall: {
            text: "Veuillez utiliser un écran de 1024px de large ou plus"
        },
        ready: {
            welcome: {
                line1: "Nous sommes prêts à vous accueillir,",
                line2: "veuillez entrer votre adresse e-mail et cliquer sur le bouton ci dessous.",
                line3: ""
            },
            input: {
                label: 'Adresse e-mail'
            },
            button: {
                text: "Commencer l'expérience"
            }
        }
    },
    cookies: {
        settings: "Réglages des cookies",
        properties: "Paramètres des Cookies",
        consentDescription: 'En cliquant sur "Accepter tous les cookies", vous acceptez le stockage de cookies dans votre navigateur pour améliorer votre expérience sur ce site, analyser et contribuer à son amélioration.',
        allow: "Accepter tous les cookies",
        moreDetails: "En savoir plus",
        types: {
            mandatory: {
                text: "Strictement nécessaire (obligatoires)",
                description: "Cookies nécessaires pour activer les fonctionnalités de base du site Web."
            }

        }
    },
    dataProvider: {
        accessError: "Nous ne parvenons pas à valider votre accès, veuillez contacter Andros",
        dialog: {
            title: "Bienvenue !",
            next: "Suivant",
            slide1: {
                line1: "Déplacez vous",
                line2: "Sur le plan",
                line3: "Cliquez, maintenez et relâchez pour vous déplacer"
            },
            slide2: {
                line1: "Cliquez sur les sélecteurs",
                line2: "pour découvrir ce qu'ils cachent"
            },
            slide3: {
                line1: "Tous les mois, nous ajoutons une avancée dans notre plan",
                line2: "Nous vous notifierons au travers de notre newsletter lorsque nous publierons un nouveau sujet"
            }
        }
    },
    main: {
        enable: "Activer",
        disable: "Désactiver",
        report: {
            url: "https://andros-rse-dynamic.s3.eu-west-3.amazonaws.com/VF+Andros-developpement-durable-2021.pdf",
            download: "Télécharger le rapport Développement durable Andros 2021"
        }

    },
    header: {
        img
    },
    misc: {
        flag: "🇫🇷"
    },
    autistics,
    carboncompensating,
    carbonneutralcandy,
    carbonreducing,
    committed,
    ecowrap,
    orchard,
    orchardwatering,
    solarpanels,
    sustainable,
    vehiclefleet,
    waterplant,
}