import ecowrapList from "../../../assets/content/ecowrap-list.png"

export default {
    title: "Le nouveau-né des emballages responsables Andros : le distributeur de desserts fruitiers",
    block1: "L’objectif d’Andros est d’atteindre, à partir de 2025, 100% d’emballages recyclables ou réutilisables.",
    block2: "91% des emballages Andros déclarés au titre de l’éco-contribution CITEO 2021 le sont d’ores et déjà.",
    block3: {
        normal1: "Après la 1ère gourde recyclable du marché lancée en 2020, Andros innove de nouveau en inventant le",
        bold: "1er distributeur de desserts fruitiers",
        normal2: ". A destination dans un premier temps de la restauration collective et au food- service, il pourra ensuite être proposé à la GMS."
    },
    ecowrapList
}