export default {
    title: "10 000 m² de panneaux photovoltaïques pour contribuer à réduire de 33% nos émissions de CO2",
    subtitle: {
        normal: "Dans le cadre de son plan Performance durable, Andros s’est fixé comme objectif ambitieux d’",
        bold: "atteindre la neutralité carbone de ses ateliers de production dès 2025."
    },
    steps: {
        title: " La réalisation de cette ambition nécessite 3 étapes :",
        measure: "mesurer précisément nos émissions de CO2 : nous le faisons depuis 2011 et désormais notre bilan carbone est annuel,",
        reduce: "réduire autant que possible nos émissions de CO2,",
        compensate: "compenser nos émissions résiduelles en achetant des crédits carbone permettant de financer des projets de stockage de carbone.",
    },
    block1: "S’agissant de la réduction de ses émissions carbone, Andros a engagé, après une décennie de diminution régulière, un très important effort d’investissement pour infléchir significativement la courbe (- 33% en 2ans).",
    block2: "En 2021-2022, l’entreprise a en effet investi 27 millions d’euros sur son principal site de production à Biars-sur-Cère :",
    list1: "changement de chaudière",
    list2: "installation de pompes à chaleur,",
    list3: "nouvelle station d’épuration permettant la valorisation du biogaz en énergie pour ses ateliers de production,",
    list4: "construction sur site d’un centre de stockage froid avec récupération de chaleur, permettant moins de camions sur les routes (auparavant les frigos loués pouvaient être éloignés jusqu’à 200 km),",
    list5: "10 000m² de panneaux photovoltaïques.",
    block3: "Cet effort sera poursuivi dans les années à avenir pour atteindre le plancher de nos émissions. Nous pourrons ensuite passer à la phase de compensation par l’achat de crédits carbone."
}
