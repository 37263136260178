import { Cookie } from "@mui/icons-material";
import { Button, Checkbox, Divider, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useData } from "../providers/DataProvider";

type CookiesProps = {};

const Cookies: React.FC<CookiesProps> = () => {
  const [consent, setConsent] = useState<"first" | "yes" | "undefined">(
    "undefined"
  );
  const [showPopup, setShowPopup] = useState(false);
  const { sounds } = useData()!;
  const { t } = useTranslation("cookies");

  // try to get cookies consent
  useEffect(() => {
    const cookieConsent = localStorage.getItem("andros-consent");
    if (!cookieConsent) {
      setConsent("first");
    } else {
      setConsent("yes");
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("andros-consent", "yes");
    setConsent("yes");
  };

  return (
    <>
      <AnimatePresence>
        {consent === "yes" && (
          <motion.div
            onClick={(e) => {
              e.stopPropagation();
              if (sounds) {
                sounds.click.other.volume = 0.5;
                sounds.click.other.currentTime = 0;
                sounds.click.other.play();
              }
              setShowPopup(true);
            }}
            initial={{ bottom: -60 }}
            animate={{ bottom: 0 }}
            exit={{ bottom: -60 }}
            style={{
              position: "fixed",
              bottom: 0,
              left: 20,
              zIndex: 10000,
              backgroundColor: "#e5e2e2",
              height: 50,
            }}
            className="hover-cursor"
            transition={{ type: "spring", bounce: 0.2, stiffness: 100 }}
            whileHover={{ scale: 1.05 }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Cookie style={{ marginLeft: 10, marginRight: 10 }} />
              <div style={{ marginRight: 10 }}>{t("settings")}</div>
            </div>
          </motion.div>
        )}
        {consent === "first" && (
          <motion.div
            initial={{ bottom: -300 }}
            animate={{ bottom: 40 }}
            exit={{ bottom: -60 }}
            style={{
              position: "fixed",
              bottom: 0,
              left: 20,
              zIndex: 10000,
              backgroundColor: "#e5e2e2",
              maxWidth: 400,
            }}
            className="hover-cursor"
            transition={{ type: "spring", bounce: 0.2, stiffness: 100 }}
          >
            <div
              style={{
                backgroundColor: "darkgreen",
                color: "white",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <Cookie style={{ marginLeft: 10, marginRight: 10 }} />
                <Typography style={{ marginRight: 10 }} variant="h6">
                  {t("properties")}
                </Typography>
              </div>
              <p style={{ padding: 10, textAlign: "justify" }}>
                {t("consentDescription")}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button onClick={acceptCookies}>{t("allow")}</Button>
              <Button color="info" onClick={() => setShowPopup(true)}>
                {t("moreDetails")}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ type: "spring" }}
            style={{
              position: "fixed",
              top: window.innerHeight / 2 - 250,
              left: window.innerWidth / 2 - 200,
              borderRadius: 10,
              zIndex: 80908,
              width: 400,
              maxHeight: "100vh",
              maxWidth: "90vw",
              backgroundColor: "darkgreen",
              color: "white",
              overflow: "hidden",
              boxShadow: "0 0 20px rgba(0,0,0,0.08)",
            }}
          >
            <div style={{ padding: 20 }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {t("settings")}
              </Typography>
              <Typography marginTop={2} textAlign="justify">
                {t("consentDescription")}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox checked disabled />
                <div style={{ color: "#c6d659", fontWeight: "bold" }}>
                  {t("types.mandatory.text")}
                </div>
              </div>
              <div
                style={{ color: "white", paddingLeft: 42, fontSize: "0.9em" }}
              >
                {t("types.mandatory.description")}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#e5e2e2",
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
              }}
            >
              <Button
                onClick={() => {
                  if (sounds) {
                    sounds.click.other.currentTime = 0;
                    sounds.click.other.volume = 0.5;
                    sounds.click.other.play();
                  }
                  acceptCookies();
                  setShowPopup(false);
                }}
              >
                {t("allow")}
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {showPopup && (
        <div
          onClick={() => {
            if (sounds) {
              sounds.click.other.currentTime = 0;
              sounds.click.other.volume = 0.5;
              sounds.click.other.play();
            }
            setShowPopup(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 80907,
          }}
        ></div>
      )}
    </>
  );
};

export default Cookies;
