import "../styles/Spinner.scss";
import { motion, MotionStyle, useSpring } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useData } from "../providers/DataProvider";
import androsFlower from "../assets/fleur-andros.svg";
import androsHighlighted from "../assets/fleur-andros-highlighted.svg";
import { Tooltip } from "@mui/material";

type SpinnerProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
  active: boolean;
  highlighted?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({
  onClick,
  style,
  active,
  highlighted,
}) => {
  const [boxesClasses, setBoxesClasses] = useState({
    box: "box",
    "box-inner": "box-inner",
  });
  const { sounds } = useData()!;
  const spinnerScale = useSpring(1);

  const setActive = () => {
    if (sounds) {
      sounds.hover.currentTime = 0;
      sounds.hover.volume = 0.5;
      sounds.hover.play();
    }
    spinnerScale.set(spinnerScale.get() * 1.5);
  };
  const setInactive = () => {
    spinnerScale.set(style?.scale || 1);
  };

  useEffect(() => {
    spinnerScale.set(style?.scale || 1);
  }, [style]);

  return (
    <>
      {(active && (
        <motion.div
          onClick={(e) => {
            if (onClick) {
              if (sounds) {
                sounds.click.item.currentTime = 0;
                sounds.click.item.volume = 0.5;
                sounds.click.item.play();
              }
              e.stopPropagation();
              onClick();
            }
          }}
          animate={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
          style={{ ...style, scale: spinnerScale } || { scale: spinnerScale }}
          onHoverStart={setActive}
          onHoverEnd={setInactive}
        >
          <img
            src={highlighted ? androsHighlighted : androsFlower}
            width={highlighted ? "90" : "50"}
            height={highlighted ? "90" : "50"}
          />
        </motion.div>
      )) || (
        <Tooltip
          enterTouchDelay={0}
          title={
            <div
              style={{
                fontSize: 14,
                padding: 6,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Cette actualité RSE Andros vous sera dévoilée ultérieurement
            </div>
          }
        >
          <motion.div
            onClick={(e) => {
              console.log("DEACTIVATED");
            }}
            transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
            style={
              { ...style, scale: spinnerScale, filter: `grayscale()` } || {
                scale: spinnerScale,
              }
            }
            onHoverStart={setActive}
            onHoverEnd={setInactive}
          >
            <img src={androsFlower} width="50" height="50" />
          </motion.div>
        </Tooltip>
      )}
    </>
  );
};

export default Spinner;
