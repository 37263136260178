import { Typography } from "@mui/material";
import commitBee from "../../assets/content/commit-bee.jpg";
import commitLabel from "../../assets/content/commit-label.png";
import commitKids from "../../assets/content/commit-kids.jpg";
import commitSheeps from "../../assets/content/commit-sheeps.jpg";
import { useTranslation } from "react-i18next";

const Commited: React.FC = () => {
  const { t } = useTranslation("committed");
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={commitLabel}
          style={{ width: "70%", marginTop: "1em", marginBottom: "1em" }}
        />
      </div>
      <Typography variant="body1" gutterBottom>
        {t("block1")}
      </Typography>
      <img
        src={commitKids}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
      <p>{t("block2")}</p>
      <img
        src={commitBee}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
      <p style={{ fontWeight: "bold" }}>{t("block3")}</p>
      <img
        src={commitSheeps}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
    </div>
  );
};

export default Commited;
