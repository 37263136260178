import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import rotatePhone from "../../assets/anims/rotate-phone.json";

const PortraitLandscape: React.FC = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{ color: "white" }}
    >
      <Lottie animationData={rotatePhone} loop={true} />
      <Typography style={{ textAlign: "center" }} variant="h6">
        {t("onboarding:portraitLandscape.text")}
      </Typography>
    </motion.div>
  );
};

export default PortraitLandscape;
