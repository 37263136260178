export default {
    title: "Andros, a pioneer in the employment of autistic workers in an industrial environment",
    subtitle: "In 2014, Andros hired Reynicker, and autistic adult with severe autism. 8 years later, he is still there, loyal to the job and still as motivated as ever when it is time to start work.",
    blocks: {
        span1: "14 other people have joined Reynicker in 2 Andros workshops:",
        span2: "10 at Andros Chilled Dairy and 5 at Andros Confectionery where the number will eventually reach 10.",
        span3: "2 other Andros Chilled Dairy and Andros Frozen Dessert workshops will soon follow the same approach.",
        span4: "By employing severely autistic people in an industrial environment with permanent contracts and standard workstations, Andros has opened a path to integrate a previously excluded population through work.",
        span5: "This experiment has become a model, since some twenty companies have already hired or are in the process of hiring, autistic people in their factories."
    }
}