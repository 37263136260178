import compensatingCredits from "../../../assets/content/compensating-credits.png"

export default {
    title: "Une compensation carbone vertueuse pour atteindre la neutralité",
    subtitle: {
        normal: "Andros s’est fixé comme objectif ambitieux d’",
        bold: "atteindre la neutralité carbone de ses ateliers de production dès 2025."
    },
    steps: {
        title: "3 étapes sont nécessaires pour y parvenir :",
        measure: "mesurer nos émissions de CO2 : Andros réalise un bilan carbone annuel;",
        reduce: "réduire au maximum nos émissions : à la fin de l’année 2022, nous aurons diminué de 45% nos émissions par rapport à notre 1er bilan carbone en 2011. Nous prévoyons de les baisser encore d’ici à 2025 (-52 à – 55%);",
        compensate: "compenser nos émissions résiduelles en achetant des crédits carbone pour financer des projets de réduction ou de stockage de carbone;"
    },
    blocks: {
        span1: "Pour assurer cette compensation,",
        span2: "Andros fait le choix d’acheter une part importante de ses crédits carbone en France sur son amont laitier : notre entreprise finance ainsi la mutation environnementale des fermes qui fournisse le lait à son activité ultra-frais.",
        span3: "Ces crédits carbone fonctionne sur un cycle de 5 ans, c’est pourquoi Andros contractualise dès aujourd’hui avec France Carbone Agri pour acheter des crédits carbone qui permettront la compensation de ses émissions à partir de 2025. Sont éligibles à ces crédits carbone, les fermes laitières qui ont effectué un diagnostic CAP2ER énergétique et carbone de leur activité."
    },
    compensatingCredits
}