export default {
    title: "Andros, pionnier de l’emploi de travailleurs autistes en milieu industriel",
    subtitle: "En 2014, Andros a embauché Reynicker, un adulte autiste atteint d’un syndrome sévère. 8 ans plus tard, il est toujours là, fidèle au poste et toujours aussi motivé le matin au moment de commencer le travail.",
    blocks: {
        span1: "14 autres personnes autistes ont rejoint Reynicker dans 2 ateliers Andros :",
        span2: "10 au sein d’Andros ultra-frais et 5 au sein d’Andros Confiserie où l’effectif sera de 10 à terme.",
        span3: "2 autres ateliers d’Andros ultra-frais et Andros surgelés s’engageront bientôt dans la même démarche.",
        span4: "En employant des personnes autistes sévères en milieu industriel, en CDI et sur des postes de travail ordinaire, Andros a ouvert une voie d’insertion par le travail à une population qui en était jusque-là exclue.",
        span5: "Une expérimentation devenue un modèle puisqu’une vingtaine d’entreprise a déjà embauché ou est en cours d’embauche de personnes autistes dans ses usines."
    }
}