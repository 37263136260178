import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import fr from "../../i18n/fr";
import loading from "../../assets/anims/loading.json";

const Loading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Lottie
        animationData={loading}
        style={{ transform: "scale(1.5)" }}
        loop={true}
      />
      <Typography style={{ textAlign: "center", padding: 30 }} variant="body1">
        {t('onboarding:loading.text')}
        {/* Nous préparons votre expérience personnalisée, veuillez patienter */}
      </Typography>
    </motion.div>
  );
};

export default Loading;
