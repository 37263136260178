import { Typography } from "@mui/material";
import compensatingDairy from "../../assets/content/compensating-dairy.jpg";
import "../../styles/Images.scss";
import { useTranslation } from "react-i18next";

const CarbonCompensating: React.FC = () => {
  const { t } = useTranslation("carboncompensating");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("subtitle.normal")}
        <span style={{ fontWeight: "bold" }}>{t("subtitle.bold")}</span>
      </Typography>
      <p>{t("steps.title")}</p>
      <ul>
        <li>{t("steps.measure")}</li>
        <li>{t("steps.reduce")}</li>
        <li>
          <span style={{ fontWeight: "bold" }}>{t("steps.compensate")}</span>
        </li>
      </ul>
      <p>
        {t("blocks.span1")}{" "}
        <span style={{ fontWeight: "bold" }}>{t("blocks.span2")}</span>
      </p>
      <img
        src={compensatingDairy}
        style={{ width: "100%" }}
        className="card-image"
      />
      <p>{t("blocks.span3")}</p>
      <img
        src={t('compensatingCredits')}
        style={{ width: "100%" }}
        className="card-image"
      />
    </div>
  );
};

export default CarbonCompensating;
