import autistics from "./content/en/autistics";
import carboncompensating from "./content/en/carboncompensating";
import carbonneutralcandy from "./content/en/carbonneutralcandy";
import carbonreducing from "./content/en/carbonreducing";
import committed from "./content/en/committed";
import ecowrap from "./content/en/ecowrap";
import orchard from "./content/en/orchard";
import orchardwatering from "./content/en/orchardwatering";
import solarpanels from "./content/en/solarpanels";
import sustainable from "./content/en/sustainable";
import vehiclefleet from "./content/en/vehiclefleet";
import waterplant from "./content/en/waterplant";
import img from "../assets/header.en.png"

export default {
    onboarding: {
        loading: {
            text: "We're personalizing your experience, please wait..."
        },
        error: {
            text: "We can't find your custom environment, please contact Andros",
        },
        portraitLandscape: {
            text: 'Please switch to landscape mode'
        },
        tooSmall: {
            text: "please use a screen with a width of at least 1024px"
        },
        ready: {
            welcome: {
                line1: "We're ready to welcome you aboard,",
                line2: "Please enter your e-mail and click the button below.",
                line3: ""
            },
            input: {
                label: 'E-mail address'
            },
            button: {
                text: "Begin the Experience"
            }
        }
    },
    cookies: {
        settings: "Cookies preferences",
        properties: "Cookies Settings",
        consentDescription: 'By clicking on "Agree to all cookies", you accept cookies will be stored in your browser to improve your user experience on the website and to take part to its improvement.',
        allow: "Agree to all cookies",
        moreDetails: "More details",
        types: {
            mandatory: {
                text: "Absolutely necessary (mandatory)",
                description: "Cookies needed to access the basic functionality of the website."
            }

        }
    },
    dataProvider: {
        accessError: "We can't validate your user access, please contact Andros",
        dialog: {
            title: "Welcome !",
            next: "Next",
            slide1: {
                line1: "Move around",
                line2: "On the map",
                line3: "Click, Hold and Drag to move around"
            },
            slide2: {
                line1: "Click on the bullets",
                line2: "to discover what they are about"
            },
            slide3: {
                line1: "Each month we add a new step to our plan",
                line2: "We'll keep you in the loop through our newsletter !"
            }
        }
    },
    main: {
        enable: "Enable",
        disable: "Disable",
        report: {
            url: "https://andros-rse-dynamic.s3.eu-west-3.amazonaws.com/Andros+sustainable+development+report+_+2021.pdf",
            download: "Download the 2021 Andros Sustainable Development report"
        }

    },
    header: {
        img
    },
    misc: {
        flag: "🇬🇧"
    },
    autistics,
    carboncompensating,
    carbonneutralcandy,
    carbonreducing,
    committed,
    ecowrap,
    orchard,
    orchardwatering,
    solarpanels,
    sustainable,
    vehiclefleet,
    waterplant,
}