export default {
    title: "Une forte baisse des émissions de CO2 d’Andros",
    subtitle: {
        normal: "Andros s’est fixé comme objectif ambitieux d’",
        bold: "atteindre la neutralité carbone de ses ateliers de production dès 2025."
    },
    steps: {
        title: "3 étapes sont nécessaires pour y parvenir :",
        measure: "mesurer nos émissions de CO2,",
        reduce: "réduire au maximum nos émissions,",
        compensate: "compenser nos émissions résiduelles en achetant des crédits carbone pour financer des projets de réduction ou de stockage de carbone."
    },
    block1: "Andros a réalisé le 1er bilan carbone annuel de ses activités en 2011. Ce bilan est désormais annuel.",
    block2: {
        normal: "Entre 2011 et 2021, nous avons réduit nos émissions de 34%.",
        bold: "2022 sera marquée par une nouvelle forte avancée puisque nous prévoyons d’atteindre en fin d’année une baisse de 45% par rapport à 2011.",
    },
    block3: "Nous continuerons nos efforts de réduction d’ici à 2025, année où nous procèderons à la compensation de nos émissions en achetant des crédits carbone."
}