import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import smallScreen from "../../assets/anims/small-screen.json";

const TooSmall: React.FC = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{ color: "white" }}
    >
      <Lottie
        animationData={smallScreen}
        style={{ transform: "scale(0.5)" }}
        loop={true}
      />
      <Typography style={{ textAlign: "center", padding: 30 }} variant="body1">
        {t("onboarding:tooSmall.text")}
      </Typography>
    </motion.div>
  );
};

export default TooSmall;
