import { Typography } from "@mui/material";
import wateringPool from "../../assets/content/watering-pool.jpg";
import wateringOrchard from "../../assets/content/watering-orchard.jpg";
import { useTranslation } from "react-i18next";

const OrchardWatering: React.FC = () => {
  const { t } = useTranslation("orchardwatering");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        <span style={{ fontWeight: "bold" }}>{t("block1.bold1")}</span>{" "}
        {t("block1.normal")}
        <span style={{ fontWeight: "bold" }}>{t("block1.bold2")}</span>
      </Typography>
      <img
        src={wateringOrchard}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
      <p>{t("block2")}</p>
      <p>
        {t("block3.normal1")}{" "}
        <span style={{ fontWeight: "bold" }}>{t("block3.bold")}</span>{" "}
        {t("block3.normal2")}
      </p>
      <img
        src={wateringPool}
        style={{ width: "100%" }}
        className="card-image"
      />
      <p style={{ fontWeight: "bold" }}>{t("block4")}</p>
    </div>
  );
};

export default OrchardWatering;
