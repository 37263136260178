import { Cookie } from "@mui/icons-material";
import { Button, Checkbox, Divider, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useData } from "../providers/DataProvider";

type CookiesProps = {};

const Cookies: React.FC<CookiesProps> = () => {
  const [showFlag, setShowFlag] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation("misc");

  useEffect(() => {
    setTimeout(() => {
      setShowFlag(true);
    }, 2000);
  }, []);

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <AnimatePresence>
        {showFlag && (
          <motion.div
            onClick={() => {
              changeLang(i18n.language === "en" ? "fr" : "en");
            }}
            initial={{ top: -50 }}
            animate={{ top: 0 }}
            exit={{ top: -50 }}
            style={{
              position: "fixed",
              top: 0,
              right: 60,
              zIndex: 10000,
              backgroundColor: "#e5e2e2",
              borderRadius: "0 0 5px 5px",
              overflow: "hidden",
              height: 35,
            }}
            className="hover-cursor"
            transition={{ type: "spring", bounce: 0.2, stiffness: 100 }}
            whileHover={{ scale: 1.15 }}
          >
            <div
              style={{
                display: "flex",
                backgroundColor: "",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ padding: 7.5 }}>{t("flag")}</div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Cookies;
