import { Button, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ready from "../../assets/anims/ready.json";
import { useData } from "../../providers/DataProvider";

const Ready: React.FC = () => {
  const { sounds, beginExperience } = useData()!;
  const [email, setEmail] = useState("");
  const params = useParams();
  const { t, i18n } = useTranslation("onboarding");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      style={{
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Lottie animationData={ready} loop={false} />
      {params.id !== "andros-land" ? (
        <>
          <div style={{ textAlign: "center", padding: 30 }}>
            {t("ready.welcome.line1")} {t("ready.welcome.line2")}
            <br /> {t("ready.welcome.line3")}
          </div>
          <TextField
            style={{ marginBottom: "1em", width: 500 }}
            label={t("ready.input.label")}
            variant="filled"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            InputProps={{ style: { backgroundColor: "white", color: "black" } }}
          />
        </>
      ) : (
        <div style={{ textAlign: "center", padding: 30 }}>
          {t("ready.welcome.line1")}
          <br /> {t("ready.welcome.line3")}
        </div>
      )}

      <Button
        onClick={() => {
          if (sounds) {
            sounds.click.other.volume = 0.5;
            sounds.click.other.play();
          }
          console.log(email);
          beginExperience(email);
        }}
        variant="contained"
      >
        {t("ready.button.text")}
      </Button>
    </motion.div>
  );
};

export default Ready;
