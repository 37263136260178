import { MutableRefObject, useCallback, useEffect, useState } from "react";
import waterPlant from "../assets/content/waterplant.jpg";
import velib from "../assets/content/vehiclefleet-velib.png";
import ppd from "../assets/content/ppd.png";
import solar from "../assets/content/solarpanels.jpg";
import wateringPool from "../assets/content/watering-pool.jpg";
import wateringOrchard from "../assets/content/watering-orchard.jpg";
import acro0 from "../assets/content/acro-0.png";
import acro1 from "../assets/content/acro-1.jpg";
import acro2 from "../assets/content/acro-2.jpg";
import acro3 from "../assets/content/acro3-client.png";
import acro3gen from "../assets/content/acro3-generic.png";
import ecowrapDispenser from "../assets/content/ecowrap-dispenser.png";
import ecowrapList from "../assets/content/ecowrap-list.png";
import commitBee from "../assets/content/commit-bee.jpg";
import commitLabel from "../assets/content/commit-label.png";
import commitKids from "../assets/content/commit-kids.jpg";
import commitSheeps from "../assets/content/commit-sheeps.jpg";
import carbonReducing from "../assets/content/carbonreducing.png";
import forest from "../assets/content/carbonneutral-forest.jpg";
import location from "../assets/content/carbonneutral-location.png";
import compensatingDairy from "../assets/content/compensating-dairy.jpg";
import compensatingCredits from "../assets/content/compensating-credits.png";
import compensatingCap2er from "../assets/content/compensating-cap2er.png";
import autistic1 from "../assets/content/autistic-1.jpg";
import autistic2 from "../assets/content/autistic-2.jpg";
import supermarket from "../assets/supermarket.png";

type AssetsPreloaderProps = {
  load: boolean;
};

const routes = [
  velib,
  ppd,
  solar,
  waterPlant,
  wateringOrchard,
  wateringPool,
  acro0,
  acro1,
  acro2,
  acro3,
  acro3gen,
  ecowrapDispenser,
  ecowrapList,
  commitBee,
  commitKids,
  commitLabel,
  commitSheeps,
  carbonReducing,
  forest,
  location,
  compensatingDairy,
  compensatingCredits,
  compensatingCap2er,
  autistic1,
  autistic2,
  supermarket,
];

const AssetsPreloader: React.FC<AssetsPreloaderProps> = ({ load }) => {
  const [assets, setAssets] = useState<
    { loaded: boolean; route: string; image: HTMLImageElement }[]
  >([]);

  useEffect(() => {
    if (load && !assets.length) {
      setAssets(
        // creating enough images
        routes.map((route) => {
          const image = new Image();
          const dataItem = { loaded: false, route, image };
          return dataItem;
        })
      );
    }
  }, [load]);

  useEffect(() => {
    if (assets.length) {
      for (const asset of assets) {
        asset.image.src = asset.route;
      }
    }
  }, [assets]);
  return <></>;
};

export default AssetsPreloader;
