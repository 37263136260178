export default {
    title: "Virtuous carbon offsetting to achieve carbon neutrality",
    subtitle: {
        normal: "Andros has set itself the ambitious goal of",
        bold: "achieving carbon neutrality in its production facilities by 2025."
    },
    steps: {
        title: "3 steps are necessary to reach this goal:",
        measure: "measure our CO2 emissions: Andros carries out an annual carbon footprint assessment;",
        reduce: "reduce our emissions as much as possible: by the end of 2022, we will have reduced our emissions by 45% compared to our 1st carbon footprint assessment in 2011. We plan to reduce them further by 2025 (- 52 to -55%);",
        compensate: "offset our residual emissions by purchasing carbon credits to finance carbon reduction or storage projects"
    },
    blocks: {
        span1: "To ensure this offsetting,",
        span2: "Andros has chosen to purchase a significant portion of its carbon credits in France from its upstream dairy business: our company is thus financing the environmental transformation of the farms that supply milk to its chilled dairy activity.",
        span3: "These carbon credits operate on a five-year cycle, which is why Andros is already contracting with France Carbon Agri to purchase carbon credits that will allow it to offset its emissions from 2025. Dairy farms that have carried out a CAP2ER energy and carbon diagnosis of their activity are eligible for these carbon credits."
    },
    compensatingCredits: ""
}