import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import customBackground from "../assets/customLand.jpg";
import placeholder from "../assets/placeholder.png";
import genericBackground from "../assets/androsLand.jpg";
import { useParams } from "react-router-dom";
import Store from "../types/store";
import Axios from "axios";
import {
  Backdrop,
  Button,
  createTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion, useSpring } from "framer-motion";
import Loading from "../components/Backdrop/Loading";
import TooSmall from "../components/Backdrop/TooSmall";
import PortraitLandscape from "../components/Backdrop/PortraitLandscape";
import Ready from "../components/Backdrop/Ready";
import DataError from "../components/Backdrop/DataError";
import AssetsPreloader from "../helpers/assetsPreloader";
import { AppSounds } from "./sounds";
import AlertSlide, { AlertSlideRef } from "../components/AlertSlide";
import { useDebouncedCallback } from "use-debounce";
import { Download, HighlightOff } from "@mui/icons-material";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Controller, Navigation, Pagination, Swiper } from "swiper";

import newsletter from "../assets/newsletter.png";
import click from "../assets/click.png";
import drag from "../assets/drag.png";
import { useTranslation } from "react-i18next";
import { POI, usePois } from "./PoiProvider";

type DataContextType = {
  background: string;
  store?: Store;
  zoomLevel: number;
  zoomInOut: (value: number) => void;
  pois: POI[];
  begin: boolean;
  beginExperience: (email?: string) => void;
  storeLogo: HTMLImageElement;
  sounds?: AppSounds;
  playPauseBackground: (action: "play" | "pause") => void;
  backgroundPlaying: boolean;
};
type DataProviderProps = {
  children: React.ReactElement;
};

export const imageDefaultsDimensions = {
  height: 4906,
  width: 7000,
};

// MUI
export const theme = createTheme({
  palette: {
    primary: {
      main: "#282c34",
    },
    secondary: {
      main: "#e2e2e2",
    },
    background: {
      paper: "#eaeaef",
      default: "#282c34",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const DataContext = createContext<DataContextType | null>(null);
export const useData = () => useContext(DataContext);
const axios = Axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  // POI's
  const { custom, generic } = usePois();
  // I18n
  const { t } = useTranslation("dataProvider");
  // Assets
  const alert = useRef(null as null | AlertSlideRef);
  const [background, setBackground] = useState("");
  const [backgroundImage] = useState(new Image());
  const [backgroundMusicPlaying, setBackgroundMusicPlaying] = useState(false);
  const [storeLogo] = useState(new Image());
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [windowRef, setWindowRef] = useState(0);
  // Assets
  // backdrop
  const [backdropVisible, setBackdropVisible] = useState(true);
  const [backdropElement, setBackdropElement] = useState<ReactElement>(<></>);
  const [isDataError, setIsDataError] = useState(false);
  const [sounds, setSounds] = useState<AppSounds>();
  const [showOnboardingDialog, setShowOnboardingDialog] = useState(false);
  // backdrop
  // experience
  const [begin, setBegin] = useState(false);
  const [controlledSwiper, setControlledSwiper] = useState<Swiper>();
  // experience
  const [zoomLevel, setZoomLevel] = useState(
    parseInt(
      Math.round(
        10 *
          (1 /
            Math.max(
              imageDefaultsDimensions.width / (2 * window.innerWidth),
              imageDefaultsDimensions.height / (2 * window.innerHeight)
            ))
      ).toFixed(1),
      10
    ) / 10
  );
  const params = useParams();
  const [pois, setPois] = useState<POI[]>([]);
  const [storeData, setStoreData] = useState<Store>();
  // Effects

  const handlePois = (positions: "custom" | "generic") => {
    if (storeData && storeData.POIs) {
      if (positions === "custom") {
        setPois(generic);
      } else {
        setPois(custom);
      }
    }
  };

  const zoomInOut = (value: number) => {
    if (
      imageDefaultsDimensions.height * (zoomLevel + value) >=
        window.innerHeight &&
      imageDefaultsDimensions.width * (zoomLevel + value) >=
        window.innerWidth &&
      zoomLevel + value <= 1
    ) {
      setZoomLevel(zoomLevel + value);
    }
  };

  const getData = async () => {
    try {
      let route: string;
      if (params.id === "andros-land") {
        route = "generic";
      } else {
        route = `store?uuid=${params.id}`;
      }
      setStoreData((await axios.get(route)).data);
    } catch (error) {
      setIsDataError(true);
    }
  };

  const playPauseBackground = (action: "play" | "pause") => {
    switch (action) {
      case "play":
        setBackgroundMusicPlaying(true);
        if (sounds) {
          sounds.background.loop = true;
          sounds.background.volume = 0.3;
        }
        sounds?.background.play();
        break;
      case "pause":
        setBackgroundMusicPlaying(false);
        sounds?.background.pause();
    }
  };

  const beginExperience = (email?: string) => {
    if (params.id === "andros-land") {
      setBegin(true);
    } else {
      // IF Pass, check pass === email else set
      if (!storeData?.Pass) {
        setBegin(true);
      } else {
        const mails = storeData.Pass.split(",").map((mail) =>
          mail.trim().toLowerCase()
        );
        if (
          storeData.Pass.trim().toLowerCase() === email?.trim().toLowerCase() ||
          (email && mails.includes(email?.trim().toLowerCase()))
        ) {
          setBegin(true);
        } else {
          // show an error
          alert.current?.showAlert({
            alertColor: "error",
            alertMessage: t("accessError"),
            timeout: 4000,
          });
        }
      }
    }
  };

  const handleDismissOnboarding = () => {
    setShowOnboardingDialog(false);
  };

  useEffect(() => {
    setBackdropElement(getBackdropElement());
  }, [backgroundLoaded, storeData, logoLoaded, isDataError]);

  const getBackdropElement = (): ReactElement => {
    if (window.innerWidth < 1024) {
      if (window.innerHeight >= 1024 && window.innerWidth >= 600) {
        return <PortraitLandscape />;
      }
      return <TooSmall />;
    }
    if ((!storeData || !backgroundLoaded || !logoLoaded) && !isDataError) {
      return <Loading />;
    }
    if (storeData && backgroundLoaded && logoLoaded && !isDataError) {
      return <Ready />;
    }
    if (isDataError) {
      return <DataError />;
    }
    return backdropElement;
  };

  useEffect(() => {
    if (params.id === "andros-land") {
      setBackground(customBackground);
    } else {
      setBackground(genericBackground);
    }
    getData();
    // attach window resize watcher
    window.onresize = () => {
      setWindowRef(Math.random());
    };
    window.ondeviceorientation = () => {
      setWindowRef(Math.random());
    };
  }, []);

  useEffect(() => {
    setBackdropElement(getBackdropElement());
  }, [windowRef]);

  useEffect(() => {
    if (storeData) {
      if (params.id === "andros-land") {
        handlePois("generic");
      } else {
        handlePois("custom");
      }
      storeLogo.onload = () => {
        setLogoLoaded(true);
      };
      storeLogo.src = storeData.Url || placeholder;
    }
  }, [storeData]);

  useEffect(() => {
    if (background) {
      backgroundImage.onload = () => {
        setBackgroundLoaded(true);
        // import sounds only when background is loaded
        import("./sounds").then((value) => {
          setSounds(value.default);
        });
      };
      backgroundImage.src = background;
    }
  }, [background]);

  useEffect(() => {
    if (begin) {
      playPauseBackground("play");
      setBackdropVisible(false);
      setShowOnboardingDialog(true);
    }
  }, [begin]);

  return (
    <DataContext.Provider
      value={{
        begin,
        background,
        store: storeData,
        zoomLevel,
        pois,
        storeLogo,
        zoomInOut,
        beginExperience,
        sounds,
        playPauseBackground,
        backgroundPlaying: backgroundMusicPlaying,
      }}
    >
      <ThemeProvider theme={theme}>
        <>
          <Backdrop
            open={backdropVisible}
            transitionDuration={{ appear: 0, enter: 0, exit: 1000 }}
            sx={{
              zIndex: 9000,
              backdropFilter: "blur(30px);",
              WebkitBackdropFilter: "blur(30px)",
              backgroundColor: "rgba(0,0,0,0.9);",
            }}
          >
            <AnimatePresence>{backdropElement}</AnimatePresence>
          </Backdrop>
          <AssetsPreloader load={backgroundLoaded} />
          <AlertSlide ref={alert} />
          {children}
        </>
      </ThemeProvider>
      <Dialog
        open={showOnboardingDialog}
        onClose={handleDismissOnboarding}
        PaperProps={{
          style: { borderRadius: 20, overflow: "hidden", width: 500 },
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div style={{ fontWeight: "bold" }}>{t("dialog.title")}</div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "space-between",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <IconButton
                style={{ margin: 0, paddingLeft: 0, marginTop: -5 }}
                onClick={handleDismissOnboarding}
              >
                <HighlightOff htmlColor="#888" />
              </IconButton>
              <div></div>
              {/* <Button
                style={{
                  padding: 0,
                  margin: 0,
                  textTransform: "none",
                  color: "#888",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (controlledSwiper) {
                    if (controlledSwiper.activeIndex < 2) {
                      controlledSwiper.slideTo(
                        controlledSwiper.activeIndex + 1
                      );
                    } else {
                      handleDismissOnboarding();
                    }
                  }
                }}
              >
                {t("dialog.next")}
              </Button> */}
            </div>
          </div>
        </DialogTitle>
        <Divider />
        <div
          style={{
            margin: 20,
            marginTop: -30,
            paddingLeft: 60,
            paddingRight: 60,
            height: 400,
          }}
        >
          <SwiperComponent
            slidesPerView={1}
            style={{ height: "100%" }}
            modules={[Pagination, Controller]}
            pagination={{ clickable: true, enabled: true }}
            onSwiper={setControlledSwiper}
          >
            {/* <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={drag} style={{ width: 130, height: 130 }} />
              <Typography
                variant="h5"
                fontWeight={"bold"}
                textAlign={"center"}
                marginTop={1}
              >
                {t("dialog.slide1.line1")}
                <br />
                {t("dialog.slide1.line2")}
              </Typography>
              <Typography textAlign={"center"} marginTop={1}>
                {t("dialog.slide1.line3")}
              </Typography>
            </SwiperSlide> */}
            <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={click} style={{ width: 130, height: 130 }} />
              <Typography
                variant="h5"
                fontWeight={"bold"}
                textAlign={"center"}
                marginTop={1}
              >
                {t("dialog.slide2.line1")}
                <br />
                {t("dialog.slide2.line2")}
              </Typography>
            </SwiperSlide>
            {/* <SwiperSlide
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={newsletter} style={{ width: 130, height: 130 }} />
              <Typography
                variant="h5"
                fontWeight={"bold"}
                textAlign={"center"}
                marginTop={1}
              >
                {t("dialog.slide3.line1")}
              </Typography>
              <Typography textAlign={"center"} marginTop={1}>
                {t("dialog.slide3.line2")}
              </Typography>
            </SwiperSlide> */}
          </SwiperComponent>
        </div>
      </Dialog>
    </DataContext.Provider>
  );
};

export default DataProvider;
