import ppd from "../../../assets/content/ppd.png"

export default {
    title: "La Performance durable d’Andros inscrite dans un plan",
    subtitle: "Entreprise familiale, française et multi-locale, Andros construit depuis plus d’un siècle son développement de manière profondément durable.",
    block1: "Cette vision de son développement, a été inscrite dans le plan Andros Performance durable qui, au fil de ses 5 piliers, définit la stratégie de l’entreprise, liste ses réalisations et fixe ses ambitions pour le futur :",
    list1: {
        bold: "moins de ressources consommées,",
        normal: "avec notamment l’ambition de neutralité carbone pour nos ateliers de production,"
    },
    list2: {
        bold: "des produits sains et des emballages éco-responsables,",
        normal: "avec notamment l’objectif de recyclabilité de tous nos emballages,"
    },
    list3: {
        bold: "protéger la biodiversité et promouvoir l’agroécologie,",
        normal: "avec notamment le développement de l’agroécologie dans nos vergers,"
    },
    list4: {
        bold: "mieux vivre dans l’entreprise et le territoire :",
        normal: "améliorer la qualité de vie au travail de nos salariés et nous engager pour une société plus solidaire,"
    },
    list5: {
        bold: "partager avec nos fournisseurs et nos collaborateurs nos ambition durable.",
        normal: "le développement durable pour tous, partout, tout le temps :"
    },
    ppd
}