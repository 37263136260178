import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import carbonReducing from "../../assets/content/carbonreducing.png";
import "../../styles/Images.scss";

const CarbonReducing: React.FC = () => {
  const { t } = useTranslation("carbonreducing");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("subtitle.normal")}
        <span style={{ fontWeight: "bold" }}>{t("subtitle.bold")}</span>
      </Typography>
      <p>{t("steps.title")}</p>
      <ul>
        <li>{t("steps.measure")}</li>
        <li>{t("steps.reduce")}</li>
        <li>{t("steps.compensate")}</li>
      </ul>
      <p>{t("block1")}</p>
      <p>
        {t("block2.normal")}{" "}
        <span style={{ fontWeight: "bold" }}>{t("block2.bold")}</span>
      </p>
      <p>{t("block3")}</p>
      <img src={carbonReducing} style={{ width: "100%" }} />
    </div>
  );
};

export default CarbonReducing;
