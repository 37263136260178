import acro0 from "../../../assets/content/acro-0.png"

export default {
    title: "Un verger agroécologique expérimental chez Andros",
    subtitle: "L’agroécologie représente l’avenir de l’agriculture pour continuer à produire pour nourrir les humains tout en préservant les écosystèmes.",
    block1: "En résumé, grâce à la technique des couverts végétaux au pied des rangs de fruits, c’est plus de vie dans les sols donc plus de fertilité donc moins d’intrans nécessaires, c’est moins d’eau utilisée grâce à l’humidité maintenue, et c’est plus de carbone absorbé !",
    block2: "C’est fort de cette conviction de l’intérêt de l’agroécologie qu’Andros a planté à Biars-sur-Cère, juste en face du siège de l’entreprises, un verger de 15 ha dans lequel toutes les espèces de fruits capables de pousser sous nos latitudes sont présents.",
    block3: "L’objectif ? Passer aux travaux pratiques de l’agroécologie pour convaincre sur le terrain ses arboriculteurs fournisseurs de l’efficacité de ces nouvelles techniques agronomiques associées à des innovations mécaniques et leur viabilité économique.",
    block4: "Un lieu unique qui démontre le savoir-faire fruitier d’Andros et qui illustre sa volonté d’accompagner son amont fruitier vers une agriculture plus soucieuse de la santé de notre planète.",
    acro0
}