export default {
    title: "The water treated by our water treatment plant is used for the irrigation of our orchard",
    block1: {
        bold1: "The 15-hectare Andros experimental orchard",
        normal: "in front of the company’s headquarters is home to all the fruit species that can grow in this part of the world. Designed to test new varieties and agroecology techniques in full-size, ",
        bold2: "it is particularly exemplary in terms of efficient water resource management."
    },
    block2: "The plant cover technique in the application of agroecological principles makes it possible to maintain moisture in the soil and thus reduce the need for irrigation. When necessary, our orchard uses a pendulum irrigation device or drip irrigation system to limit the amount of water used.",
    block3: {
        normal1: "Finally, the last innovation of the Andros orchard: in addition to the recovery of rainwater in a retention basin, the orchard is connected to the outlet of the water treatment plant. Rather than pumping into the groundwater table,",
        bold: "the orchard’s irrigation system uses the water treated by the plant.",
        normal2: "This is brand new, and therefore allows for a water discharge of excellent quality!"
    },
    block4: "We will share this efficient water resource management with our tree-grower suppliers, who we will welcome in our experimental orchard to share about good agricultural practices."
}