import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import velib from "../../assets/content/vehiclefleet-velib.png";

const VehicleFleet: React.FC = () => {
  const { t } = useTranslation("vehiclefleet");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("block1")}
      </Typography>
      <p>{t("list.title")}</p>
      <ul>
        <li>{t("list.list1")}</li>
        <li>{t("list.list2")}</li>
      </ul>
      <img
        src={velib}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
      />
    </div>
  );
};

export default VehicleFleet;
