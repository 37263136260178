export default {
    title: "A new wastewater treatment plant to help reduce our CO2 emissions by 33% and to improve the quality of our effluent discharges",
    subtitle: {
        normal: "As part of its Sustainable Performance Plan, Andros has set itself the goal of",
        bold: "achieving carbon neutrality in its production facilities by 2025 and helping preserve biodiversity."
    },
    gocarbonneutral: "Achieving carbon neutrality",
    steps: {
        title: "3 steps are necessary to reach this goal:",
        measure: "accurately measure our CO2 emissions: we have been doing this since 2011 and now our carbon footprint assessment is annual,",
        reduce: "reduce our emissions as much as possible,",
        compensate: "offset our residual emissions by purchasing carbon credits to finance carbon reduction or storage projects.",
    },
    block1: "With regard to the reduction of its carbon emissions, after a decade of steady decline, Andros has undertaken a very considerable investment effort to significantly curb the curve (- 33% in 2 years).",
    block2: "In 2021-2022, the company invested 27 million euros in its main production site in Biars-sur-Cère:",
    list1: "change of boiler,",
    list2: "installation of heat pumps,",
    list3: "a new wastewater treatment plant to convert biogas into energy for its production facilities,",
    list4: "construction of an on-site cold storage centre with heat recovery, allowing fewer lorries on the road (previously rented refrigerators could be up to 200 km away),",
    list5: "10,000 m² of photovoltaic panels.",
    block3: "This effort will continue in the years to come to reach the lowest limit of our emissions. We will then be able to move on to the offsetting phase through the purchase of carbon credits.",
    block4: "Helping preserve biodiversity",
    block5: {
        normal1: "Our wastewater treatment plant contributes to the preservation of the environment",
        bold: "by improving and making the treatment of our wastewater more reliable before it is discharged into the natural environment,",
        normal2: "thus protecting the biodiversity of the rivers that surround our production facilities."
    }
}
