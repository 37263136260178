import ppd from "../../../assets/content/ppd.en.png"

export default {
    title: "Andros’s sustainable Performance is written in a plan",
    subtitle: "As a family-owned, French, multi-local company, Andros has been building its development in a profoundly sustainable way for over a century.",
    block1: "This vision of its development is enshrined in the Andros Sustainable Performance Plan, which through its 5 pillars, defines the company’s strategy, lists its achievements and sets its ambitions for the future:",
    list1: {
        bold: "fewer resources consumed,",
        normal: "with the ambition of carbon neutrality for our production facilities,"
    },
    list2: {
        bold: "healthy products and eco-responsible packaging,",
        normal: "including the objective of the recyclability of all our packaging,"
    },
    list3: {
        bold: "protecting biodiversity and promoting agroecology,",
        normal: "in particular with the development of agroecology in our orchards,"
    },
    list4: {
        bold: "living better in the company and the region:",
        normal: "improving the quality of life at work for our employees and committing ourselves to a more united society,"
    },
    list5: {
        bold: "sharing our sustainable ambitions with our suppliers and employees.",
        normal: "sustainable development for all, everywhere, all the time:"
    },
    ppd
}