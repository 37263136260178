import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  motion,
  // useAnimation,
  useDragControls,
  useMotionValue,
  animate,
  AnimatePresence,
  useSpring,
} from "framer-motion";
import { Button /*, IconButton */ } from "@mui/material";
import { imageDefaultsDimensions, useData } from "../providers/DataProvider";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import adFrame from "../assets/adframe.png";
import { useParams } from "react-router-dom";
import CollapsableDrawer from "../components/CollapsableDrawer";
import {
  Add,
  Download,
  Remove,
  VolumeMute,
  VolumeUp,
} from "@mui/icons-material";
import { useDebouncedCallback } from "use-debounce";
// import magazineMockup from "../assets/magazine-mockup.png";
import { useTranslation } from "react-i18next";
import { POI } from "../providers/PoiProvider";
import supermarket from "../assets/supermarket.png";
import { isPoiActive } from "../helpers/is-poi-active";
import { isPoiHighlighted } from "../helpers/is-poi-highlighted";

const getScreenCenterImageCoordinates = ({
  zoomLevel,
  x,
  y,
}: {
  zoomLevel: number;
  x: number;
  y: number;
}): { x: number; y: number } => {
  const centerScreenXOnScaledImage = -1 * x + window.innerWidth / 2;
  const centerScreenYOnScaledImage = -1 * y + window.innerHeight / 2;

  const centerScreenXOnOriginalImage = centerScreenXOnScaledImage / zoomLevel;
  const centerScreenYOnOriginalImage = centerScreenYOnScaledImage / zoomLevel;

  return { x: centerScreenXOnOriginalImage, y: centerScreenYOnOriginalImage };
};

const getOffsetValues = ({
  zoomLevel,
  imageX,
  imageY,
}: {
  zoomLevel: number;
  imageX: number;
  imageY: number;
}): { x: number; y: number } => {
  const scaledImageX = imageX * zoomLevel;
  const scaledImageY = imageY * zoomLevel;

  const offsetX = window.innerWidth / 2 - scaledImageX;
  const offsetY = window.innerHeight / 2 - scaledImageY;
  return { x: offsetX, y: offsetY };
};

const androsDownloadwidth = 540;

const Main: React.FC = () => {
  // I18n
  const { t } = useTranslation("main");
  // Data
  const {
    background,
    pois,
    zoomLevel,
    zoomInOut,
    storeLogo,
    sounds,
    backgroundPlaying,
    begin,
    store,
    playPauseBackground,
  } = useData()!;
  const [previousZoom, setPreviousZoom] = useState(zoomLevel);
  const params = useParams();
  const androsDownloadLeftPosition = useSpring(
    window.innerWidth / 2 - androsDownloadwidth / 2
  );
  const resizeCallback = useDebouncedCallback(() => {
    androsDownloadLeftPosition.set(
      window.innerWidth / 2 - androsDownloadwidth / 2
    );
  }, 100);
  // Sounds
  const [muted, setMuted] = useState(sounds?.background.paused);

  const toggleMusic = () => {
    if (backgroundPlaying) {
      playPauseBackground("pause");
    } else {
      playPauseBackground("play");
    }
  };
  // Motion
  const dragControls = useDragControls();
  const [buttonX, setButtonX] = useState(Math.min(window.innerWidth - 80));
  const volumeX = useSpring(window.innerWidth - 230, {
    damping: 60,
    stiffness: 200,
    mass: 8,
  });
  const x = useMotionValue(
    getOffsetValues({
      zoomLevel,
      imageX: imageDefaultsDimensions.width / 2 + 500,
      imageY: imageDefaultsDimensions.height / 2 - 400,
    }).x
  );
  const y = useMotionValue(
    getOffsetValues({
      zoomLevel,
      imageX: imageDefaultsDimensions.width / 2 + 500,
      imageY: imageDefaultsDimensions.height / 2 - 400,
    }).y
  );
  const animatedHeight = useMotionValue(
    imageDefaultsDimensions.height * zoomLevel
  );
  const animatedWidth = useMotionValue(
    imageDefaultsDimensions.width * zoomLevel
  );
  const containerRef = useRef<HTMLDivElement>(null);
  // Motion

  // Drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [side, setSide] = useState<"left" | "right">("left");
  const [currentPOI, setCurrentPOI] = useState<POI>();
  const buttonResizeCallback = useDebouncedCallback(() => {
    if (side === "left") {
      setButtonX(window.innerWidth - 80);
      volumeX.set(window.innerWidth - 230);
    } else {
      setButtonX(Math.min(window.innerWidth / 10, 80));
      volumeX.set(160);
    }
  }, 50);
  // Drawer
  useEffect(() => {
    // onload set window onresize to take into account the zoom button positions
    window.addEventListener("resize", buttonResizeCallback);
    window.addEventListener("resize", resizeCallback);
  }, []);

  const handleSpinnerClick = (poi: POI) => {
    if (showDrawer) {
      if (sounds) {
        sounds.drawer.close.volume = 0.5;
      }
      sounds?.drawer.close.play();
    }
    setShowDrawer(false);
    if (poi.position.x * -1 * zoomLevel + window.innerWidth / 2 < 0) {
      setSide("left");
      setButtonX(window.innerWidth - 80);
      volumeX.set(window.innerWidth - 230);
    } else {
      setSide("right");
      setButtonX(80);
      volumeX.set(160);
    }
    setTimeout(() => {
      setCurrentPOI(poi);
    }, 400);
    setTimeout(() => {
      if (sounds) {
        sounds.drawer.open.volume = 0.5;
      }
      sounds?.drawer.open.play();
      setShowDrawer(true);
    }, 600);
    animate(
      x,
      Math.max(
        Math.min(poi.position.x * -1 * zoomLevel + window.innerWidth / 2, 0),
        -1 * imageDefaultsDimensions.width * zoomLevel + window.innerWidth
      ),
      {
        duration: 0.8,
        ease: "easeInOut",
      }
    );
    animate(
      y,
      Math.max(
        Math.min(poi.position.y * -1 * zoomLevel + window.innerHeight / 2, 0),
        -1 * imageDefaultsDimensions.height * zoomLevel + window.innerHeight
      ),
      {
        duration: 0.8,
        ease: "easeInOut",
      }
    );

    // set component and open i!!
  };

  useEffect(() => {
    if (zoomLevel) {
      const screenCenterImageCoordinates = getScreenCenterImageCoordinates({
        zoomLevel: previousZoom,
        x: x.get(),
        y: y.get(),
      });
      const offsetValues = getOffsetValues({
        zoomLevel,
        imageX: screenCenterImageCoordinates.x,
        imageY: screenCenterImageCoordinates.y,
      });
      // reposition to max bottom right if the screen overflows onto the map:
      if (
        x.get() <
        imageDefaultsDimensions.width * -1 * zoomLevel + window.innerWidth
      ) {
        animate(
          x,
          imageDefaultsDimensions.width * -1 * zoomLevel + window.innerWidth,
          { duration: 0.3, ease: "easeInOut" }
        );
      } else {
        animate(x, Math.min(offsetValues.x, 0), {
          duration: 0.3,
          ease: "easeInOut",
        });
      }
      if (
        y.get() <
        imageDefaultsDimensions.height * -1 * zoomLevel + window.innerHeight
      ) {
        animate(
          y,
          imageDefaultsDimensions.height * -1 * zoomLevel + window.innerHeight,
          { duration: 0.3, ease: "easeInOut" }
        );
      } else {
        animate(y, Math.min(offsetValues.y, 0), {
          duration: 0.3,
          ease: "easeInOut",
        });
      }
      animate(animatedHeight, zoomLevel * imageDefaultsDimensions.height, {
        duration: 0.6,
        ease: "easeInOut",
      });
      animate(animatedWidth, zoomLevel * imageDefaultsDimensions.width, {
        duration: 0.6,
        ease: "easeInOut",
      });
    }
    setPreviousZoom(zoomLevel);
  }, [zoomLevel]);

  return (
    <div
      style={{
        backgroundColor: "#c9d251",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
      ref={containerRef}
    >
      <motion.div
        animate={{ x: buttonX }}
        style={{
          position: "absolute",
          bottom: 50,
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
        }}
        transition={{
          type: "spring",
          damping: 60,
          stiffness: 200,
          mass: 8,
        }}
      >
        <Button
          onClick={() => {
            if (sounds) {
              sounds.click.zoom.currentTime = 0;
              sounds.click.zoom.volume = 0.5;
              sounds.click.zoom.play();
            }
            zoomInOut(0.1);
          }}
          style={{ rotate: "45deg" }}
        >
          <div
            style={{
              height: 40,
              width: 40,
              backgroundColor: "#bb2626",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                height: "80%",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Add
                htmlColor="white"
                style={{ rotate: "45deg", fontSize: "2em" }}
              />
            </div>
          </div>
        </Button>
        <Button
          onClick={() => {
            if (sounds) {
              sounds.click.zoom.currentTime = 0;
              sounds.click.zoom.volume = 0.5;
              sounds.click.zoom.play();
            }
            zoomInOut(-0.1);
          }}
          style={{ rotate: "45deg", marginTop: "1em" }}
        >
          <div
            style={{
              height: 40,
              width: 40,
              backgroundColor: "#bb2626",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                height: "80%",
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Remove
                htmlColor="white"
                style={{ rotate: "45deg", fontSize: "2em" }}
              />
            </div>
          </div>
        </Button>
      </motion.div>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100vw",
          zIndex: 4,
          paddingBottom: 10,
        }}
      >
        <Header />
      </div>
      {/* Main drag component */}
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
          if (showDrawer) {
            if (sounds) {
              sounds.drawer.close.volume = 0.5;
            }
            sounds?.drawer.close.play();
          }
          setShowDrawer(false);
        }}
        layout
        dragControls={dragControls}
        style={{
          //   height: animatedHeight,
          //   width: animatedWidth,
          height: zoomLevel * imageDefaultsDimensions.height,
          width: zoomLevel * imageDefaultsDimensions.width,
          cursor: "grab",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          x,
          y,
        }}
        dragConstraints={containerRef}
        dragTransition={{
          bounceDamping: 20,
          bounceStiffness: 200,
        }}
        drag
        transition={{}}
      >
        {/* map of each dot based on the filtered data */}
        {pois.map((poi) => (
          <Spinner
            onClick={() => {
              handleSpinnerClick(poi);
            }}
            key={poi.key}
            style={{
              position: "absolute",
              left: poi.position.x * zoomLevel,
              top: poi.position.y * zoomLevel,
              scale: 0.6 * zoomLevel + 0.4,
              transition: "transform 0.3sec ease-in-out",
            }}
            active={isPoiActive(poi.key, store!)}
            highlighted={isPoiHighlighted(poi.key, store!)}
          />
        ))}
        {/* positioned and scaled logo, if not generic */}
        {(params.id !== "andros-land" && (
          <>
            <img
              src={storeLogo.src}
              alt="logo"
              style={{
                position: "absolute",
                left: 6488 * zoomLevel,
                top: 3805 * zoomLevel,
                // scale: zoomLevel,
                aspectRatio: "2.19",
                objectFit: "cover",
                objectPosition: "center center",
                height: 87 * zoomLevel,
                transform: `rotateZ(-30deg) skew(-30deg)`,
                transformOrigin: "top left",
              }}
            />
            {/* <img
              src={adFrame}
              style={{
                position: "absolute",
                left: 6484 * zoomLevel,
                top: 3706 * zoomLevel,
                transform: `scale(${(zoomLevel * (87 / 69)).toFixed(1)})`,
                transformOrigin: "top left",
              }}
            /> */}
          </>
        )) || (
          <>
            <img
              src={supermarket}
              alt="logo"
              style={{
                position: "absolute",
                left: 794 * zoomLevel,
                top: 774 * zoomLevel,
                // scale: zoomLevel,
                aspectRatio: "3.65",
                objectFit: "cover",
                objectPosition: "center center",
                height: 63 * zoomLevel,
                transform: `rotateZ(30deg) skew(30deg)`,
                transformOrigin: "top left",
              }}
            />
          </>
        )}
      </motion.div>
      {/* Detail drawer */}
      <AnimatePresence>
        {showDrawer && <CollapsableDrawer side={side} poi={currentPOI} />}
      </AnimatePresence>
      <motion.div
        style={{
          position: "absolute",
          bottom: 45,
          width: 120,
          left: volumeX,
          padding: 10,
          backgroundColor: "#bb2626",
          display: "flex",
          alignItems: "center",
        }}
        onClick={toggleMusic}
      >
        {backgroundPlaying ? (
          <VolumeMute fontSize="large" htmlColor="white" />
        ) : (
          <VolumeUp fontSize="large" htmlColor="white" />
        )}
        <div style={{ margin: "auto", color: "white", fontWeight: "bold" }}>
          {backgroundPlaying ? t("disable") : t("enable")}
        </div>
      </motion.div>
      {/* <AnimatePresence>
        {begin && (
          <motion.div
            onClick={(e) => {
              window.open(t("report.url"), "_blank");
              e.stopPropagation();
              if (sounds) {
                sounds.click.other.currentTime = 0;
                sounds.click.other.play();
              }
            }}
            initial={{ bottom: -110 }}
            animate={{ bottom: 0 }}
            exit={{ bottom: -110 }}
            style={{
              position: "fixed",
              backgroundColor: "rgb(187, 38, 38)",
              bottom: 0,
              left: androsDownloadLeftPosition,
              zIndex: 1,
              height: 30,
              width: androsDownloadwidth,
              padding: 10,
              marginBottom: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              color: "white",
            }}
            className="hover-cursor"
            transition={{ type: "spring", bounce: 0.2, stiffness: 100 }}
            whileHover={{ scale: 1.15 }}
          >
            <img
              style={{
                width: 100,
                left: -30,
                position: "absolute",
                rotate: "-15deg",
              }}
              src={magazineMockup}
            />
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: 5 }}>{t("report.download")}</div>
              <Download />
            </div>
          </motion.div>
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default Main;
