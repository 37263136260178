import "./styles/App.css";
import Main from "./views/Main";
import DataProvider from "./providers/DataProvider";
import PoiProvider from "./providers/PoiProvider";
import Cookies from "./components/Cookies";
import Lang from "./components/Lang";

function App() {
  return (
    <PoiProvider>
      <DataProvider>
        <>
          <Main />
          <Cookies />
          <Lang />
        </>
      </DataProvider>
    </PoiProvider>
  );
}

export default App;
