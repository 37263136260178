export default {
    title: "L’eau traitée par notre station d’épuration utilisée pour l’irrigation de notre verger",
    block1: {
        bold1: "Le verger expérimental Andros de 15 hectares",
        normal: "en face du siège de l’entreprise accueille toutes les espèces de fruits pouvant pousser sous nos latitudes. Conçu pour tester de nouvelles variétés et les techniques de l’agroécologie en grandeur nature,",
        bold2: "il se veut en particulier exemplaire sur le plan de la gestion sobre de la ressource en eau."
    },
    block2: "La technique des couverts végétaux en application des principes agroécologique permet notamment de maintenir de l’humidité dans les sols et de réduire ainsi les besoins en irrigation. Lorsque celle-ci s’avère néanmoins indispensable, notre verger utilise un dispositif d’irrigation pendulaire ou en goutte à goutte pour limiter la quantité d’eau utilisée.",
    block3: {
        normal1: "Enfin, dernière innovation du verger Andros : en plus de la récupération d’eau de pluie dans un bassin de rétention, le verger est raccordé à la sortie de la station d’épuration : plutôt que de pomper dans la nappe phréatique,",
        bold: "le système d’irrigation du verger utilise l’eau traitée par la station.",
        normal2: "Celle-ci est toute neuve et permet donc le rejet d’une eau d’excellente qualité !"
    },
    block4: "Cette gestion sobre de la ressource en eau, nous la partagerons avec nos fournisseurs arboriculteurs que nous accueillerons dans notre verger expérimental pour échanger sur les bonnes pratiques agricoles."
}