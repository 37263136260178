import React, { createContext } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Autistics from "../helpers/PoiComponents/Autistics";
import CarbonCompensating from "../helpers/PoiComponents/CarbonCompensating";
import CarbonNeutralCandy from "../helpers/PoiComponents/CarbonNeutralCandy";
import CarbonReducing from "../helpers/PoiComponents/CarbonReducing";
import Commited from "../helpers/PoiComponents/Commited";
import EcoWrap from "../helpers/PoiComponents/EcoWrap";
import Orchard from "../helpers/PoiComponents/Orchard";
import OrchardWatering from "../helpers/PoiComponents/OrchardWatering";
import SolarPanels from "../helpers/PoiComponents/SolarPanels";
import Sustainable from "../helpers/PoiComponents/Sustainable";
import VehicleFleet from "../helpers/PoiComponents/VehicleFleet";
import WaterPlant from "../helpers/PoiComponents/WaterPlant";

export type POI = {
  key: string;
  name: string;
  position: { x: number; y: number };
  component: React.ReactElement;
};

type PoiContextType = {
  custom: POI[];
  generic: POI[];
};
const PoiContext = createContext<PoiContextType>(
  null as unknown as PoiContextType
);

export const usePois = () => useContext(PoiContext);

const PoiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  const custom = [
    {
      key: "solarpanels",
      name: "solarpanels:title",
      position: { x: 3250, y: 1850 },
      component: <SolarPanels />,
    },
    {
      key: "waterplant",
      name: "waterplant:title",
      position: { x: 4350, y: 1950 },
      component: <WaterPlant />,
    },
    {
      key: "autistics",
      name: "autistics:title",
      position: { x: 5400, y: 970 },
      component: <Autistics />,
    },
    {
      key: "orchard",
      name: "orchard:title",
      position: { x: 6700, y: 3550 },
      component: <Orchard />,
    },
    {
      key: "carbonreducing",
      name: "carbonreducing:title",
      position: { x: 3500, y: 2500 },
      component: <CarbonReducing />,
    },
    {
      key: "carboncompensating",
      name: "carboncompensating:title",
      position: { x: 1000, y: 2800 },
      component: <CarbonCompensating />,
    },
    {
      key: "ecowrap",
      name: "ecowrap:title",
      position: { x: 3730, y: 2380 },
      component: <EcoWrap />,
    },
    {
      key: "committed",
      name: "committed:title",
      position: { x: 6350, y: 2900 },
      component: <Commited />,
    },
    {
      key: "vehiclefleet",
      name: "vehiclefleet:title",
      position: { x: 3100, y: 2500 },
      component: <VehicleFleet />,
    },
    {
      key: "orchardwatering",
      name: "orchardwatering:title",
      position: { x: 6100, y: 3400 },
      component: <OrchardWatering />,
    },
    {
      key: "carbonneutralcandy",
      name: "carbonneutralcandy:title",
      position: { x: 5850, y: 800 },
      component: <CarbonNeutralCandy />,
    },
    {
      key: "sustainable",
      name: "sustainable:title",
      position: { x: 4250, y: 3100 },
      component: <Sustainable />,
    },
  ] as POI[];

  const generic = [
    {
      key: "solarpanels",
      name: "solarpanels:title",
      position: { x: 3400, y: 1700 },
      component: <SolarPanels />,
    },
    {
      key: "waterplant",
      name: "waterplant:title",
      position: { x: 3900, y: 850 },
      component: <WaterPlant />,
    },
    {
      key: "autistics",
      name: "autistics:title",
      position: { x: 1040, y: 1040 },
      component: <Autistics />,
    },
    {
      key: "orchard",
      name: "orchard:title",
      position: { x: 3400, y: 3100 },
      component: <Orchard />,
    },
    {
      key: "carbonreducing",
      name: "carbonreducing:title",
      position: { x: 4900, y: 1900 },
      component: <CarbonReducing />,
    },
    {
      key: "carboncompensating",
      name: "carboncompensating:title",
      position: { x: 600, y: 400 },
      component: <CarbonCompensating />,
    },
    {
      key: "ecowrap",
      name: "ecowrap:title",
      position: { x: 5200, y: 1800 },
      component: <EcoWrap />,
    },
    {
      key: "committed",
      name: "committed:title",
      position: { x: 2250, y: 3100 },
      component: <Commited />,
    },
    {
      key: "vehiclefleet",
      name: "vehiclefleet:title",
      position: { x: 4400, y: 2450 },
      component: <VehicleFleet />,
    },
    {
      key: "orchardwatering",
      name: "orchardwatering:title",
      position: { x: 2900, y: 2800 },
      component: <OrchardWatering />,
    },
    {
      key: "carbonneutralcandy",
      name: "carbonneutralcandy:title",
      position: { x: 650, y: 1280 },
      component: <CarbonNeutralCandy />,
    },
    {
      key: "sustainable",
      name: "sustainable:title",
      position: { x: 4600, y: 2200 },
      component: <Sustainable />,
    },
  ] as POI[];

  return (
    <PoiContext.Provider value={{ custom, generic }}>
      {children}
    </PoiContext.Provider>
  );
};

export default PoiProvider;
