import ecowrapList from "../../../assets/content/ecowrap-list.en.png"

export default {
    title: "The new arrival of responsible Andros packaging : the fruit dessert dispenser",
    block1: "Andros’s objective is to achieve 100% recyclable or reusable packaging by 2025.",
    block2: "91% of Andros packaging declared for the 2021 CITEO eco-contribution is already recyclable.",
    block3: {
        normal1: "After the 1st recyclable pouch on the market launched in 2020, Andros is innovating again by inventing the",
        bold: "1st fruit dessert dispenser",
        normal2: ". First intended for collective catering and food service, it could then be offered to retailers."
    },
    ecowrapList
}