import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import forest from "../../assets/content/carbonneutral-forest.jpg";
import location from "../../assets/content/carbonneutral-location.png";

const CarbonNeutralCandy: React.FC = () => {
  const { t } = useTranslation("carbonneutralcandy");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("subtitle")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("block1")}
      </Typography>
      <img
        src={forest}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
      <Typography marginTop={"1em"}>{t("block2")}</Typography>
      <img
        src={location}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
    </div>
  );
};

export default CarbonNeutralCandy;
