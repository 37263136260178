import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import autistic1 from "../../assets/content/autistic-1.jpg";
import autistic2 from "../../assets/content/autistic-2.jpg";

const Autistics: React.FC = () => {
  const { t } = useTranslation("autistics");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("subtitle")}
      </Typography>
      <img
        src={autistic2}
        style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}
        className="card-image"
      />
      <p>
        <span style={{ fontWeight: "bold" }}>{t("blocks.span1")}</span>{" "}
        {t("blocks.span2")}
      </p>
      <p style={{ fontWeight: "bold" }}>{t("blocks.span3")}</p>
      <p>
        {t("blocks.span4")}{" "}
        <span style={{ fontWeight: "bold" }}>{t("blocks.span5")}</span>
      </p>
      <img src={autistic1} style={{ width: "100%" }} className="card-image" />
    </div>
  );
};

export default Autistics;
