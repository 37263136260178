import { Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { POI } from "../providers/PoiProvider";

type CollapsableDrawerProps = {
  side: "left" | "right";
  poi?: POI;
};

const CollapsableDrawer: React.FC<CollapsableDrawerProps> = ({ side, poi }) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ translateX: `${side === "left" ? "-" : ""}40vw` }}
      animate={{ translateX: "0vw" }}
      exit={{ translateX: `${side === "left" ? "-" : ""}40vw` }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      style={{
        zIndex: 3,
        position: "fixed",
        top: 0,
        height: "100vh",
        width: "40vw",
        maxWidth: "800px",
        backgroundColor: "#e0d9c4",
        display: "flex",
        [side]: 0,
      }}
    >
      <div
        style={{
          padding: 40,
          flex: 1,
          paddingTop: window.innerHeight * 0.08,
          paddingBottom: 0,
          color: "#363430",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"} fontSize={32} gutterBottom>
          {t(poi?.name as any)}
        </Typography>
        <Divider />
        {poi?.component}
      </div>
    </motion.div>
  );
};

export default CollapsableDrawer;
