import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ppd from "../../assets/content/ppd.png";
import "../../styles/App.css";

const Sustainable: React.FC = () => {
  const { t } = useTranslation("sustainable");

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        paddingTop: "1em",
        paddingBottom: 60,
      }}
    >
      <p>{t("subtitle")}</p>
      <p>{t("block1")}</p>
      <ul>
        <li>
          <span className="bold">{t("list1.bold")}</span>{" "}
          {t("list1.normal")}
        </li>
        <li>
          <span className="bold">{t("list2.bold")}</span>{" "}
          {t("list2.normal")}
        </li>
        <li>
          <span className="bold">{t("list3.bold")}</span>{" "}
          {t("list3.normal")}
        </li>
        <li>
          <span className="bold">{t("list4.bold")}</span>{" "}
          {t("list4.normal")}
        </li>
        <li>
          {t("list5.normal")}
          <span className="bold"> {t("list5.bold")}</span>
        </li>
      </ul>
      <img
        src={t("ppd")}
        style={{
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
          backgroundColor: "white",
          overflow: "hidden",
          paddingBottom: 10,
        }}
        className="card-image"
      />
    </div>
  );
};

export default Sustainable;
